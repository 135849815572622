@use "../global";

#edd_checkout_cart_form {
    thead {
        border-bottom: 1px solid #efefef;
    }

    .edd_cart_item_name,
    .edd_cart_item td:first-child,
    .edd_cart_total {
        padding: 16px;
    }
}

.edd_form {
    padding: 48px 0;

    @media(min-width: global.$mq_tablet) {
        padding: 72px 0;
    }

    #edd_checkout_user_info,
    #edd_purchase_submit {
        margin: 0 auto auto 0;
        padding: 0;

        legend {
            text-align: left;
            border-bottom: transparent;
            margin: 0 0 24px;
            position: static;
            display: block;
            width: 100%; 
            font-size: 24px;
        }
    }

    #edd-first-description,
    #edd-last-description {
        display: none;
    }

    #edd-email-wrap,
    #edd-first-name-wrap,
    #edd-last-name-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .edd-input {
            width: 100%;
            max-width: 480px;
        }
    }

    #edd-first-name-wrap,
    #edd-last-name-wrap {
        float: right;
        width: calc(50% - 10px);
    }

    #edd-first-name-wrap {
        margin-left: 8px;

        @media(min-width: global.$mq_mobile) {
            margin-left: 10px;
        }
    }

    #edd-last-name-wrap {
        margin-right: 8px;

        @media(min-width: global.$mq_mobile) {
            margin-right: 10px;
        }
    }

    #edd_purchase_submit {
        padding: 0;

        #edd_final_total_wrap {
            padding: 0 0 16px;
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid;
            margin-bottom: 48px;
        }
    }
}

.card-expiration, 
#edd_checkout_login_register p.field-holder, 
#edd_checkout_login_register p.field-holder:last-child, 
.fes-el {
    display: flex;
    flex-direction: column;
}

#edd_register_account_fields {
    .edd-description {
        display: flex;
    }
}

#edd_checkout_form_wrap legend {
    border-bottom: none;
}