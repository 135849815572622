@use "../global";

.booklet-review {
    position: relative;
    width: max-content;
    min-width: 220px;
    margin: 0 10rem;

    &__paper {
        position: absolute;
        bottom: 0;
        right: 0;
        width: max-content;
        min-width: 220px;
        height: 100%;
        padding: 16px;
        box-shadow: 0 0 5px 3px rgba(global.$color-sand, 0.05);
    }

    &_bottom {
        background: global.$color-curtain;
        z-index: 0;
        transition: all .3s ease-out;
    }
    &_middle {
        margin-bottom: 6px;
        margin-right: 6px;
        background: global.$color-paper;
        z-index: 1;
        transition: all .3s ease-out;
    }
    &_top {
        margin-bottom: 6px;
        margin-right: 6px;
        background: global.$color-light;
        z-index: 2;
        transition: margin .3s ease-out;
    }

    &__hole {
        border-left: 20px dotted global.$color-curtain;
        display: block;
    }

    &__title {
        height: max-content;
        margin: 0 1rem 0 0.75rem;
        padding: 0;
        border-right: 0.5px solid global.$color-dark;
        font-size: 24px;
    }

    &:hover {
        .booklet-review_bottom {
            transition: margin .3s ease-out;
        }
        .booklet-review_middle {
            margin-bottom: 8px;
            transition: margin .3s ease-out;
        }
        .booklet-review_top {
            margin-bottom: 8px;
            transition: margin .3s ease-out;
        }
    }

    &__link:hover {
        color: global.$color_sand;
        transition: all 0.2s ease;
    }
}