@use "../global";

#edd_register_fields a {
    color: inherit;
}

#edd_checkout_user_info,
#edd_cc_fields,
#edd_cc_address,
#edd_purchase_submit,
#edd_register_fields,
#edd_login_fields,
#edd_simple_shipping_fields {
    margin-bottom: 30px;
}

#edd_login_fields {
    padding-top: 60px;
}

#edd_register_fields #edd_checkout_user_info {
    padding: 0
}

.boxed-heading,
#edd_checkout_form_wrap legend {
    text-transform: uppercase;
    line-height: 1.6em;
}

#edd_checkout_form_wrap #edd_variable_pricing_switcher-fieldset {

    span {
        display: block;
    }

    legend {
        position: static;
        padding: 10px 0;
        display: block;
        font-size: 20px;
        background: none;
    }
} 

#edd_variable_pricing_switcher-fieldset .edd-variable-pricing-switcher {
    margin-bottom: 10px;
    font-size: 1em;
}

#edd-stripe-manage-cards legend,
#edd-stripe-manage-cards legend {
    color: inherit;
    text-transform: uppercase;
    top: 30px;
    position:relative;
    margin: 0;
    font-size: 20px;
    border-bottom: 0;
}

.boxed .author-contact-area .lightbox-title {
    font-size: 24px;
    font-weight: 700;
}

.boxed {
    .boxed-head .lightbox-title,
    .boxed-head h1 {
        margin: 0;
        color: global.$color_dark;
    }

    .lightbox-title {
        color: global.$color_dark;
        // margin: 0 0 40px;
        text-transform: uppercase;
    }

    .lightbox-subtitle {
        color: inherit;
        margin-bottom: 10px;
    }
}

.field-holder,
.card-expiration,
#edd_checkout_login_register p.field-holder,
#edd_checkout_login_register p.field-holder:last-child,
.fes-el {
    position: relative;
    margin-bottom: 24px;

    @media(min-width: global.$mq_tablet) {
        margin-bottom: 40px;
    }
}

.fes-el.repeat {
    background-color: transparent;
}

.field-holder.featured_image {
    background: none;
    text-align: left;
}

.boxed .fes-label label {
    color: inherit;
}

.boxed div.fes-form fieldset .fes-fields textarea,
.boxed div.fes-form fieldset .fes-fields input[type=text],
.boxed div.fes-form fieldset .fes-fields input[type=email] {
    border: none;
}

.olam-contact .field-holder {
    background: transparent;
}

.holder-focus .fes-label label {
    bottom: auto;
}

div.fes-form fieldset.fes-el.user_pass {
    margin-bottom: 0;

    .field-holder {
        margin-top: 40px;
    }

    .field-holder:first-child {
        margin-top: 0
    }
}

.field-holder {
    label .demo-icon {
        font-size: 1.2em;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    select,
    textarea {
        line-height: 24px;
        height: 58px;
        padding: 16px 20px;
        position: relative;
        z-index: 1;
        margin: 0;
        border: none;
        font-size: 1em;
    }

    select option {
        color: #000;
    }
}

.olam-contact {
    .field-holder input[type="text"],
    .field-holder input[type="email"],
    .field-holder input[type="password"],
    .field-holder select,
    .field-holder textarea {
        border: 1px solid rgba(0,0,0,0.05);
        background: rgba(0,0,0,0.05);
    }

    .field-holder textarea {
        min-height: 58px;
        height: auto;
    }

    .alert {
        margin-bottom: 40px;
    }
}

.card-expiration {
    background: transparent;
    min-height: 58px;

    label {
        bottom: auto; 
        display: inline-block; 
        vertical-align: middle;
    }
}

#edd_checkout_form_wrap .card-expiration {
    select.edd-select.edd-select-small {
        display: inline; 
        width: 60px; 
        background-color: rgba(0, 0, 0, 0.12); 
        margin: 12px 12px; 
        padding: 5px 12px; 
        height: 34px; 
        color: global.$color_light; 
        border: 1px solid transparent;
    }

    span.exp-divider {
        display: none;
    }
}

#edd-stripe-manage-cards, 
#edd-stripe-manage-cards {
    margin: 50px auto;
}

#edd-stripe-manage-cards {
    .card-meta .card-expiration {
        min-height: auto; 
        margin-bottom: auto; 
        background: initial;
    }

    .boxed-body .edd-stripe-card-item {
        padding: 20px;
    }

    .edd-stripe-card-item .card-actions a.delete {
        color: #f33;
    }

    .edd-stripe-card-item .card-actions a {
        border-bottom: 1px dotted;
    }

    .edd-stripe-card-item .card-address-fields input, 
    .edd-stripe-card-item .card-address-fields select,
    .edd-stripe-add-new-card input.edd-input, 
    .edd-stripe-add-new-card textarea.edd-input,
    .edd-stripe-add-new-card select.edd-select {
        width: 100%;
        display: block;
    }

    .edd-stripe-card-item .card-update-form select {
        height: inherit;
    }

    .edd-stripe-card-item .card-update-form label {
        font-weight: 400;
    }

    select.card-expiry-month, 
    select.card-expiry-year {
        width: 48%; 
        display: inline-block; 
        margin:0;
    }

    .edd-stripe-card-item .card-details .card-last-4 {
        font-weight: 500;
    }

    .card-address-fields span {
        display: block;
    }

    .edd-stripe-card-item .card-update-form > label {
        margin-bottom: 40px;
        font-weight:700;
    }

    .edd-stripe-add-new-card span.edd-description {
        margin: 0;
        opacity: 0.8;
    }

    .edd-stripe-add-new-card > label {
        position: relative;
        font-weight: 700;
    }

    .edd-stripe-add-new-card #edd-card-wrap label[for="edd-card-element"] {
        position: relative;
        top: 0!important;
        line-height: 1;
    }

    .edd-stripe-add-new-card legend {
        top: 0;
        margin-bottom: 20px;
    }

    .edd-stripe-add-new-card .field-holder label {
        top:42px;
    }

    #edd-card-wrap label {
        top: -32px !important;
        left: 0px !important;
    }

    .field-holder .edd-alert {
        padding: 5px 10px;
    }

    #edd-stripe-card-errors:not(:empty) {
        color: #ff4141;
    }
}


.edd-stripe-cancel-update {
    display: block;
}

.edd-stripe-card-item .exp-divider {
    width: 5%;
}

#edd-stripe-add-new-card fieldset {
    padding: 20px;
}

#edd-stripe-manage-cards .edd-stripe-add-new-card input.edd-input,
#edd-stripe-manage-cards .edd-stripe-add-new-card select.edd-select, 
#edd-stripe-card-element.StripeElement {
    padding: 16px 20px;
}

#edd-stripe-manage-cards .edd-stripe-add-new-card label {
    display: inline-block;
    position: absolute;
    line-height:inherit;
    z-index: 10;
    font-weight: 400;
}

#edd-stripe-manage-cards input[type="submit"].edd-stripe-submit-update,
#edd-stripe-add-new-card input[type="submit"].edd-stripe-add-new {
    border: 0;
    font-weight: bold;
}

.StripeElement {
    /*background-color: #FFFFFF!important;*/
}
/*
.StripeElement--focus {
    color: inherit!important;
}
*/

.edd-stripe-new-card #edd-stripe-card-element.StripeElement {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

input.edd-stripe-existing-card {
    top: 2px;
    position: relative;
}

.edd-card-selector-radio .edd-stripe-card-radio-item.selected {
    background: none;
    background-color: transparent!important;
    border: none;
}

.edd-stripe-card-radio-item.new-card-wrapper span {
    width: auto;
}

.edd-stripe-card-radio-item.new-card-wrapper span:after,
.edd-stripe-card-radio-item.new-card-wrapper span:after {
    border: none;
    position: unset;
    top: unset;
    left: unset;
}

.edd-stripe-card-selector + .edd-stripe-new-card {
    margin-top: 30px;
}

.edd-stripe-card-radio-item.new-card-wrapper input[type="radio"] + label span {
    position: unset;
    vertical-align: unset;
}

.edd-stripe-card-radio-item.new-card-wrapper input[type="radio"] + label span:before {
    background: none;
}

#edd_checkout_form_wrap .edd-card-selector-radio label {
    line-height: 1em;
    padding-left: 0;
}

.edd-alert.edd-stripe-alert.edd-alert-error {
    color: #f44336;
    padding: 10px;
}

p#edd-card-name-wrap {
    margin-bottom: 20px;
}

.boxed .boxed-body input[type="submit"],
// .edd-submit, 
#edd-purchase-button, 
// input[type="submit"].edd-submit,
.wrapper div.fes-form .fes-submit input[type=submit] {
    min-width: 226px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 1em;
    padding: 15px 25px;
    margin: 0;
    max-width: 100%;
}

.wrapper div.fes-form .fes-submit input[type=submit] {
    box-shadow: 5px 5px 0 rgba(0,0,0,0.1);
}

.boxed div.fes-form .fes-submit input[type=submit]:hover,
.wrapper div.fes-form .fes-submit input[type=submit]:hover {
    background: #fff;
}

.sweet-alert input{
    display: none!important;
}

.edd-cart-saving-button {
    min-width: inherit;
    padding: 8px 15px;
}

.sidebar .cart-box {
    .edd-submit, 
    #edd-purchase-button, 
    input[type="submit"].edd-submit {
        width: 226px;
        max-width: 100%;
        min-width: inherit;
        margin-bottom: 0;
    }

    .edd_purchase_submit_wrapper a.edd-add-to-cart {
        box-shadow: 5px 5px 0 rgba(0,0,0,0.1);
    }    
}

.sidebar .cart-box .edd_download_purchase_form,
.sidebar .edd-free-downloads-direct-download-link.edd-submit {
    margin-bottom: 20px;
    margin-top: 20px;
}

.sidebar .edd-cart {
    .edd_checkout a,
    a.btn-checkout {
        padding: 5px;

        &:before {
            content: none;
            display: none;
        }
    }
}

#edd_purchase_submit {
    padding-top: 60px;
}

.boxed .boxed-body input[type="submit"]:hover{
    background: global.$color_light !important;
}

#edd_login_fields a {
    color: inherit;
}

.edd-submit:before, 
.edd_checkout a:before,
a.btn-checkout:before {
    font-size: 1.3em;
    vertical-align: text-bottom;
}

.boxed .signup-icon {
    font-size: 40px;
    padding-top: 20px;

    span {
        display: inline-block;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

#fes-profile-form-title,
#fes-submission-form-title,
#fes-vendor-contact-form-title,
#fes-login-form-title,
#fes-registration-form-title {
    color: inherit !important;
    margin: 0 0 55px;
    text-transform: uppercase;
    font-size:40px;
    border-bottom:0;
    line-height: 1em;
}

.fes-vendor-contact-form textarea#message {
    min-height: 150px;
}

.wrap-forms label {
    font-size: 1em !important;
    font-weight: normal !important;
    margin-bottom: 3px !important;
}

.lightbox {
    &__container {
        padding: 48px 20px;
        display: block;
        background: global.$color_light;

        @media(min-width: global.$mq_mobile) {
            padding: 48px;
        }
    }

    &__form {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;

        .input__label,
        .input.input__full {
            max-width: 480px;
        }
    }
}