@use "../global";

.event {
    position: relative;
    width: 100%;
    height: max-content;
    min-height: 200px;

    @media(min-width: global.$mq_mobile) {
        width: calc(100% - 40px);
        margin: 0 0 0 auto;
    }

    @media(min-width: global.$mq_tablet) {
        width: calc(100% - 72px);
        min-height: 230px;
    }

    &__img {
        opacity: 0.2;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__img,
    &__img * {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    &__img,
    &__content-con {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }

    &__content-con {
        margin: 20px;

        @media(min-width: global.$mq_tablet) {
            margin: 48px;
        }
    }

    &__subtitle {
        font-size: 12px;

        @media(min-width: global.$mq_tablet) {
            font-size: 18px;
        }
    }

    &__title {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 16px;
        line-height: 1.5;

        @media(min-width: global.$mq_tablet) {
            font-size: 24px;
            margin-top: 16px;
            margin-bottom: 32px;
        }
    }

    &__desc {
        display: block;
        color: global.$color-ground;
        font-size: 14px;

        @media(min-width: global.$mq_tablet) {
            display: block;
            font-size: 16px;
        }
    }
}