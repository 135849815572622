@use "../global";

.section-booklet,
.page-booklet {
    background: global.$color_paper;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &__post {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .post-item {
            margin: auto;
        }
    }

    &__book {
        width: 100%;
        max-width: 200px;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        position: relative;
        box-shadow: -8px 8px 12px rgba(global.$color-mud, 0.2);
        transition: box-shadow 0.3s ease-in-out;

        @media (min-width: global.$mq_tablet) {
            max-width: 240px;
            height: 350px;
        }

        &:hover {
            box-shadow: -6px 12px 36px rgba(global.$color-mud, 0.2);
            transition: box-shadow 0.3s ease-in-out;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__item-header {
        writing-mode: vertical-rl;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(global.$color-light, 0.5);
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 10px;
            display: block;
            height: 100%;
            border-right: 2px solid global.$color_ground;
            -webkit-filter: blur(2px);
            filter: blur(1.75px);
            z-index: 1;
            opacity: 0.175;
        }
    }

    &__item-titles {
        margin: 16px 24px 16px auto;
    }

    &__item-header-title,
    &__item-header-subtitle {
        color: global.$color-dark;
    }

    &__item-header-title {
        margin: 0;
        line-height: 1.4;
    }

    &__item-header-subtitle {
        margin: 0;
        font-size: 14px;
    }

    &__item-bottom-content {
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;

        @media (min-width: global.$mq_tablet) {
            margin-bottom: 64px;
        }
    }

    &__item-bottom-title {
        color: global.$color-dark;
    }

    &__item-bottom-desc {
        color: global.$color-ground;
        line-height: 1.8;
    }
}