@use "../global";

.top {
    
    .row > * {
        padding: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-right: 0 !important;
        margin-left: 0 !important;
        position: relative;

        @media(min-width: global.$mq_tablet) {
            flex-direction: row;
        }

        .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    &__slider-con, 
    &__slider-wrapper {
        width: 100%;
        height: 100%;
    }

    &__slider-con {
        width: 100%;
        height: 100%;
        max-height: 100%;
        position: relative;
        
        @media(min-width: global.$mq_tablet) {
            width: 70%;
            margin: 0 auto auto 0;
        }
    }

    &__slider-wrapper {
        // margin-bottom: 40px;
        max-height: calc(100% - 40px);
        overflow: hidden;
        
        @media(min-width: global.$mq_tablet) {
            margin-bottom: 64px;
            max-height: calc(100% - 64px);
        }
    }

    &__slider {
        width: 100%;
        height: 240px;

        @media(min-width: global.$mq_mobile) {
            height: 360px;
        }

        @media(min-width: global.$mq_tablet) {
            height: 480px;
        }

        @media(min-width: 1280px) {
            height: 37.500vw;
        }

        * {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__slider-pagination {
        padding: 0 20px;
        align-items: center;
        justify-content: flex-end;

        @media(min-width: global.$mq_mobile) {
            padding: 0 48px;
        }

        @media(min-width: global.$mq_tablet) {
            padding: 0 120px;
            justify-content: flex-start;
        }

        .swiper-pagination-bullet,
        .swiper-pagination-bullet-active {
            width: 32px;
            height: 4px;
            margin: 0;
            border-radius: 0;

            @media(min-width: global.$mq_mobile) {
                width: 48px;
            }

            @media(min-width: global.$mq_tablet) {
                width: 64px;
                justify-content: flex-start;
            }
        }

        .swiper-pagination-bullet {
            background: global.$color_shadow;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: global.$color_ground;
        }
    }

    &__content-overlayed {
        margin: auto;
        height: 570px;

        @media(min-width: global.$mq_tablet) {
            position: absolute;
            right: 0;
            left: 0;
            width: calc(100% - 160px);
            max-width: 2000px;
            height: 700px;
        }
    }

    &__overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(0deg, global.$color_light, transparent);
        z-index: 1;

        @media(min-width: global.$mq_tablet) {
            height: 480px;
        }

        @media(min-width: global.$mq_tablet) {
            top: 0;
            right: -1px;
            width: 30%;
            height: 100%;
            background: linear-gradient(-90deg, global.$color_light, global.$color_light 30%, transparent);
        }
    }

    &__text-wrap {
        width: 100%;
        max-height: 540px;

        @media(min-width: global.$mq_tablet) {
            position: absolute !important;
            top: 0;
            z-index: 1;
            right: 0;
            width: 30%;
            padding-top: 108px;
            margin-bottom: 64px;
        }

        @media(min-width: 1200px) {
            max-height: calc(42.188vw - 5.375vw);
            padding-top: 8.375vw;
        }

        @media(min-width: 1440px) {
            max-height: calc(42.188vw - 5.375vw);
            padding-top: 9.375vw;
        }
    }

    &__title {
        margin-left: 24px;

        @media(min-width: global.$mq_tablet) {
            margin-left: 40px;
        }
    }

    &__content {
        p:not(:last-of-type) {
            margin-right: 0;
            margin-left: 1em;
        }

        p:last-of-type {
            margin-right: 0;
            margin-left: 0;
        }

        a {
            border-right: 0.5px solid global.$color_dark;
            padding-right: 2px;
            transition: all 0.3s ease;
        }

        a:hover {
            border-right: 0.5px solid global.$color_sand;
        }
    }
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 !important;
}