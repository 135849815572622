@use "../global";

.single-booklet .header {
    width: auto;

    @media(min-width: global.$mq_mobile) {
        width: 100%;
    }

    &.transparent {
        background: transparent;
    }
}

.header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    z-index: 9;
    padding: 0 0 0 12px;
    background: rgba(global.$color_light, 0.9);
    transition: all 0.4s ease-in-out;

    @media(min-width: global.$mq_mobile) {
        padding: 0 16px 0 24px;
        height: 80px;
    }

    &.transparent {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));

        @media(min-width: global.$mq_mobile) {
            height: 88px;
        }

        .heading__xl {
            font-size: 32px;

            @media(min-width: global.$mq_mobile) {
                font-size: 48px;
            }
        }
    }

    ul,
    ul li {
        list-style-type: none;
    }

    &__title-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
    }

    .nav {
        &__con,
        &__con * {
            list-style: none;
        }

        &__menu-con {
            writing-mode: vertical-rl;
            margin-left: auto;
            margin-right: auto;
            display: flex;
        }

        &__menu-con li:not(:last-of-type) {
            margin-left: 16px;
        }
    }

    .searchform {
        position: relative;
        overflow: hidden;
        margin: 0 24px;
        z-index: 1;

        input[type="text"] {
            width: 200px;

            @media(min-width: global.$mq_tablet) {
                width: 240px;
            }
        }
    }

    &__shop-cart,
    &__nav-toggle {
        width: 64px;
        height: 64px;
        cursor: pointer;

        @media(min-width: global.$mq_mobile) {
            width: 80px;
            height: 80px;
        }
    }

    &__shop-cart {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;

        .cart-widget .cart-btn svg {
            width: 32px;
            height: 32px;
        }

        .cart-widget .cart-btn .cart-btn__qty {
            width: 20px;
            height: 20px;
            background: global.$color_ground;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            position: absolute;
            bottom: -6px;
            right: -6px;
        }
    }

    &__nav-toggle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;

        &::after {
            background: transparent;
        }

        span {
            height: 1px;
            background: global.$color_dark;
            display: block;
            margin: 0 auto 5px;
            width: 26px;
            position: relative;
        }

        span:nth-of-type(1) {
            animation: menu-bar01 .75s forwards;
            transition: all .35s ease-in-out;
        }
    
        span:nth-of-type(2) {
            transition: all .25s .25s;
            opacity: 1;
        }
    
        span:nth-of-type(3) {
            animation: menu-bar02 .75s forwards;
            transition: all .35s ease-in-out;
        }
    
        @keyframes menu-bar01 {
            0% {
                transform: translateY(6px) rotate(45deg);
            }
            50% {
                transform: translateY(6px) rotate(0);
            }
            100% {
                transform: translateY(0) rotate(0);
            }
        }
    
        @keyframes menu-bar02 {
            0% {
                transform: translateY(-6px) rotate(-45deg);
            }
            50% {
                transform: translateY(-6px) rotate(0);
            }
            100% {
                transform: translateY(0) rotate(0);
            }
        }
    
        &.enabled span:nth-of-type(1) {
            animation: active-menu-bar01 .75s forwards;
        }
        &.enabled span:nth-of-type(2) {
            opacity: 0;
        }
        &.enabled span:nth-of-type(3) {
            animation: active-menu-bar03 .75s forwards;
        }
    
        @keyframes active-menu-bar01 {
            0% {
                transform: translateY(0) rotate(0);
            }
            50% {
                transform: translateY(6px) rotate(0);
            }
            100% {
                transform: translateY(6px) rotate(45deg);
            }
        }
    
        @keyframes active-menu-bar03 {
            0% {
                transform: translateY(0) rotate(0);
            }
            50% {
                transform: translateY(-6px) rotate(0);
            }
            100% {
                transform: translateY(-6px) rotate(-45deg);
            }
        }
    
        &:hover {
            span:nth-of-type(1) {
                transform: translateX(10px);
                transition: all .35s ease-in-out;
            }
            span:nth-of-type(3) {
                transform: translateX(-10px);
                transition: all .35s ease-in-out;
            }
        }
    }

    &__con {
        position: fixed;
        top: 0;
        right: 0;
        display: block;
        width: auto;
        max-width: 100%;
        width: auto;
        padding: 96px 48px 48px;
        height: 100%;
        will-change: transform;
        transform: translateX(20%);
        transition: opacity 0.4s ease-in-out, 
                    transform 0.4s ease-in-out, 
                    visibility 0.4s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        background: global.$color_light;
        overflow: auto;

        @media(min-width: global.$mq_mobile) {
            min-width: 300px;
        }
    
        &.enabled {
            transform: translateX(0);
            opacity: 1;
            z-index: 10;
            visibility: visible;
            transition: opacity 0.4s ease-in-out, 
                        transform 0.4s ease-in-out, 
                        visibility 0.4s ease-in-out;
        }
    }

    &__overlay {
        width: 100%;
        height: 100%;
        background: rgba(global.$color-paper, 0.9);
        will-change: opacity;
        transform: translateX(20%);
        transition: opacity 0.4s ease-in-out;
        opacity: 0;
        z-index: -1;

        &.enabled {
            position: fixed;
            top: 0;
            right: 0;
            transform: translateX(0);
            transition: opacity 0.4s ease-in-out;
            opacity: 1;
            z-index: 8;
        }
    }

    .nav ul li a {
        padding: 0 15px;
        color: global.$color_dark;
    }

    ul.shop-nav .cart-widget .cart-btn {
        padding: 7px 15px 6px 12px;
        position: relative;

        span,
        svg {
            z-index: 100; 
            margin-right: 0;

            @media(min-width: global.$mq_mobile) {
                margin-right: 8px;
            }
        }

        span {
            display: block;
            width: 24px;
            height: 24px;
            background: #aaa;
            line-height: 24px;
            text-align: center;
            color: white;
            position: absolute;
            top: 0;
            right: 0;
            font-weight: 600;
            border-radius: 100%;
        }

        svg {
            width: 32px;
            height: 32px;
            position: relative;
        }
    }

    &__search {
        &_dt {
            display: none;

            @media(min-width: global.$mq_mobile) {
                display: block;
            }
        }

        &_mb {
            display: block;

            @media(min-width: global.$mq_mobile) {
                display: none;
            }
        }
    }
}

.booklet .header .searchform {
    margin: 0;
}