@use "../global";

.social {
    &__input {

        @media(min-width: global.$mq_mobile) {
            margin-right: 16px;
        }
    }

    &__widget {
        flex-direction: column;
    }

    &__widget-con {
        &_single,
        &_multi {
            flex-direction: column;
            align-items: center;

            @media(min-width: global.$mq_tablet) {
                flex-direction: row;
            }
        }

        &_single {
            justify-content: space-around;
        }

        &_multi {
            justify-content: space-between;
        }
    }

    &__facebook,
    &__twitter {
        align-items: flex-start;
        height: 480px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__facebook {
        margin-bottom: 32px;
        overflow-y: hidden;
        width: 100%;
        max-width: 500px;
        border: 1px solid global.$color_curtain;

        @media(min-width: global.$mq_tablet) {
            margin-bottom: 0;
        }
    }

    &__twitter {
        width: 100%;
        max-width: 500px;
        border-bottom: 1px solid global.$color_curtain;

        @media(min-width: global.$mq_tablet) {
            width: 400px;
            max-width: unset;
        }

        * {
            width: 100%;
        }
    }

    .button__register {
        min-width: 100px;

        @media(min-width: global.$mq_tablet) {
            min-width: 200px;
        }
    }
}