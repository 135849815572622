@use "../global";

input,
select,
.input {
    border-radius: 0;
}

input[type="text"]:not(#s), 
input[type="email"], 
input[type="password"], 
input[type="tel"], 
input[type="number"], 
textarea, 
select,
.input {
    width: 100%;
    max-width: 480px;
    height: 48px;
    font-family: global.$typography;
    padding: 12px 22px;
    font-size: 16px;
    background: global.$color_paper;
    box-shadow: none;
    border: none;
    line-height: 1.8rem;
    color: global.$color_dark;

    &::placeholder {
        font-family: global.$typography;
        color: global.$color_sand;
    }
}

#searchform {
    #s {
        border-bottom: 1px solid global.$color_sand;
        background: transparent;
    }

    div:after {
        height: 24px;
        width: 24px;
        margin: 12px;
        padding: 12px;
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
        line-height: 48px;
        text-align: center;
        border-left: none;
        z-index: 110;
    }
}

// .boxed,
#edd_checkout_user_info,
#edd_cc_fields,
#edd_cc_address,
#edd_purchase_submit,
#edd_register_fields,
#edd_checkout_login_register,
.modal-dialog,
#edd_simple_shipping_fields {
    max-width: 480px;
    // margin: 0 auto; 
    position: relative;
}

.input {
    &__full {
        width: 100%;
        max-width: 480px;

        // @media(min-width: global.$mq_mobile) {
        //     width: 75%;
        // }

        @media(min-width: global.$mq_tablet) {
            width: 100%;
            max-width: 480px;
        }
    }

    &__label {
        margin-bottom: 8px;
        text-align: left;
    }
}