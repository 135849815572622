@use "../global";

button,
.button,
input[type="button"],
input[type="submit"] {
    cursor: pointer;
    text-decoration: none;
}

button,
input[type="button"],
input[type="submit"]:not(#searchsubmit),
.button_solid,
.button_outlined {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: 200px;
    height: 48px;
    font-size: 16px;
    box-shadow: none;
    padding: 16px 0;
}

.button_solid,
input[type="submit"] {
    background: global.$color_ground;
    color: global.$color_light !important;
    font-weight: 600;
    border: none;
    // padding: 16px;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        color: global.$color_light;
        background: rgba(global.$color_ground, 0.75);
        transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    }
}

.button_outlined {
    background: transparent;
    filter: blur(0.8);
    color: global.$color_dark;
    border: 1px solid global.$color_shadow;
    transition: color 0.3s ease-in-out, 
                background 0.3s ease-in-out, 
                border 0.3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        color: global.$color_dark;
        opacity: 0.75;
        transition: color 0.3s ease-in-out, 
                    background 0.3s ease-in-out, 
                    border 0.3s ease-in-out;
    }
}