@use "../global";

.footer {
    color: global.$color_dark;
    background-position: center top;
    position: relative;

    &_general {
        display: flex;
        padding: 0 0 32px;
        background: global.$color_curtain;
        text-align: center;
    }

    &_booklet {
        background: transparent;
        text-align: right;

        .footer__copy {
            margin: 3rem 2rem;
        }
    }

    ul li a {color: inherit;}
    h6 {color: inherit; margin-bottom: 1em;}
    .footer-links {padding: 15px 0;}

    &__title-main {
        margin-right: 16px;
        font-size: 18px;

        @media(min-width: global.$mq_tablet) {
            font-size: 24px;
        }
    }

    &__title-sub,
    &__copy {
        font-size: 12px;

        @media(min-width: global.$mq_tablet) {
            font-size: 14px;
        }
    }

    &__block-con {
        flex-direction: column;
        align-items: center;

        @media(min-width: global.$mq_tablet) {
            flex-direction: row;
        }
    }

    &__content-con {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        margin: 32px auto 24px;

        @media(min-width: global.$mq_mobile) {
            flex-direction: row;
            margin: 32px auto;
        }
    }

    &__menu-item-con {
        display: flex;
    }

    &__menu-vert {
        align-items: flex-start;
        margin-bottom: 24px;

        li {
            padding: 8px 0;
            text-align: left;

            @media(min-width: global.$mq_mobile) {
                padding: 8px 64px 8px 0;
            }
        }
    }

    .social-icons {
        padding: 10px 0;
        text-align: center;

        @media(min-width: global.$mq_mobile) {
            text-align: unset;
            margin-bottom: 30px;
        }

        a {
            color: global.$color_mud; 
            border: none;
        }

        a .icon {
            border: none;
            height: 40px;
            width: 40px;
            margin: 3px;
            display: inline-block;
        }

        li {
            display: inline-block;
            padding: 0;
        }

        li a svg {
            width: 40px;
            height: 40px;
            fill: global.$color_mud;
        }
    }
}