@use "../global";

.article-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    height: 100%;

    &__desc {
        p {
            font-size: 14px;
            margin-bottom: 0;

            @media(min-width: global.$mq_mobile) {
                font-size: 16px;
            }
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    article {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        overflow: hidden;
        height: 100%;
    }

    &__post {
        border-top: 1px solid global.$color_shadow;
        border-bottom: 1px solid global.$color_shadow;
        width: 100%;
        flex-shrink: 0;
        margin-bottom: -1px;
        padding: 0;

        @media(min-width: global.$mq_tablet) {
            margin-bottom: 24px;
        }

        &:hover .article-list__background img {
            transform: scale(1.065);

        }

        .posted-item .post-single-categories {
            line-height: normal;
            margin-bottom: 6px;
            align-items: flex-start;

            @media(min-width: global.$mq_tablet) {
                margin-bottom: 12px;
            }
        }

        .posted-item .post-single-tags {
            line-height: 24px;
        }
    }

    &__background {
        position: relative;
        height: 114px;
        overflow: hidden;

        @media(min-width: global.$mq_tablet) {
            height: 140px;
        }

        img {
            width: 100%;
            height: 100%;
            opacity: 0.2;
            object-fit: cover;
            transition: transform .25s linear;
        }
    }

    &__item-header,
    &__item-header:hover {
        position: absolute;
        top: 0;
        left: 0;
        padding: 16px;
        color: global.$color_dark;
        backdrop-filter: blur(3px);

        @media(min-width: global.$mq_tablet) {
            padding: 24px;
        }
    }

    &__item-header-title {
        font-weight: normal;
    }

    &__item-header-date {
        font-size: 14px;
    }

    &__item-cat {
        padding: 0 16px 16px;

        @media(min-width: global.$mq_tablet) {
            padding: 0 24px 24px;
        }
    }

    &__item-tag {
        border-bottom: 1px solid global.$color_dark;
        margin: 0 16px 16px 0;
        display: inline-block;
        line-height: 2;

        &:hover {
            color: global.$color_sand;
            border-bottom: 1px solid global.$color_sand;
        }
    }
}

article {
    .download,
    .page,
    .hentry {
        padding: 24px 0;
        background: global.$color_light;
        margin: 30px 0;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);

        &:last-child {
            padding-bottom: 30px;
        }
    }

    .sticky {
        margin-bottom: 30px; 
        padding-bottom: 30px; 
        padding-top: 30px; 
        overflow: hidden;
        position: relative;
    }

    .post-head {
        h2 {
            margin: 0; 
            padding: 0; 
            line-height: 1.5em;
            backdrop-filter: blur(2px);
        }
    }

    .posted-item {
        backdrop-filter: blur(2px);
    }

    .post-single-categories svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        flex-shrink: 0;
    }

    .post-single-categories ul,
    .post-single-categories li {
        list-style: none;
        padding: 0;
        margin: 0 4px 0 0;
        display: inline;
    }

    .post-content {
        padding: 16px 16px 8px;
        color: global.$color-ground;
        line-height: 1.8;

        @media(min-width: global.$mq_tablet) {
            padding: 24px 24px 20px;
        }
    }

    .section-tags__tag-item {
        margin-left: 0;
    }
}

article.sticky + article.post,
article.sticky + article.download,
article.sticky + article.page,
article.sticky + article.hentry {
    padding-top: 30px;
}