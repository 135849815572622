.page-404 {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        line-height: 1.45;
    }

    a {
        text-decoration: underline;
    }
}