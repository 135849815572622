@use "../global";

.article-single {
	.width__mid.spacing__p-t-80_single {
		padding-top: 80px;
	}

    .page-head {
		padding: 24px 0 40px;

		@media(min-width: global.$mq_tablet) {
			padding: 80px 0;
		}

		h1 {
			overflow-wrap: break-word;
		}

		a {
			color: global.$color_dark;
			transition: opacity 0.25s ease-in-out;
		}

		a:hover {
			opacity: 0.75;
		}
	}

    &__title {
        font-weight: 500;
        margin: auto auto 16px;
		line-height: 1.45;
    }

	&__tags {
		margin-bottom: 32px;

		@media(min-width: global.$mq_tablet) {
			margin-bottom: 32px;
		}
	}

	&__intro {
		color: global.$color_mud;
		line-height: 1.8;
		text-indent: 1em;
	}

	&__centerize {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		@media(min-width: 992px) {
			flex-direction: row;
		}
	}

	&__content {
		ul li,
		ol li {
			word-break: break-word;
		}
	}

	.col-md-2 {
		width: 100%;
		max-width: 680px;
		margin: 0 auto;

		@media(min-width: 992px) {
			width: 16.66666667%;
			max-width: unset;
			margin: 0 0 auto auto;
		}
	}

	.width__desc {
		margin: auto;
	}

	&__social-con {
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;

		@media(min-width: 680px) {
			flex-wrap: nowrap;
		}
	}

	.single-post {
		
		.wp-block-embed.wp-embed-aspect-16-9 {
			padding-top: 56.25%;
			position: relative;
		}

		.wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
		}

		.wp-block-embed.wp-embed-aspect-16-9 iframe {
			width: 100%;
			height: 100%;
		}

		.wp-block-embed iframe {
			width: 100%;
		}

		.wp-embedded-content {
			width: 100%;
		}
	}
}

.blog_page_subtitle,
.blog_page_subtitle a {
	color: global.$color_dark;

	@media(min-width: 1400px) {
		text-align: right;
		margin-right: 24px;
		line-height: 40px;
	}
}

.blog-page-subtitle {
	margin-bottom: 0;
	position: relative;
	font-weight: 300;
}

.blog-page-subtitle a {
	text-transform: capitalize; 
}

.type-post {
	.post-content {
		h1:not(.heading__lg),
		h2:not(.heading__lg), 
		h3:not(.heading__lg), 
		h4:not(.heading__lg), 
		h5:not(.heading__lg), 
		h6:not(.heading__lg) {
			margin-top: 40px;
			margin-bottom: 24px;
			font-weight: 500;
			line-height: 1.45;
	
			@media(min-width: global.$mq_mobile) {
				margin-top: 80px;
				margin-bottom: 40px;
			}
		}
	
		a:not(.button):not(.toc_list li a){
			color: global.$color_dark;
			border-bottom: 0.5px solid global.$color_sand;
			line-break: anywhere;
		}

		p {
			line-height: 1.875;		
		}
	
		.olam-post-featured-image {
			margin: 0;
		}
	
		img {
			max-width: 100%;
			height: auto;
		}
	
		blockquote {
			color: global.$color_dark;
	
			cite {
				font-size: 12px;
				color: global.$color_ground;
	
				@media(min-width: global.$mq_mobile) {
					font-size: 14px;
				}
			}

			p {
				margin: 0;
			}
		}

		dd {margin-left: 36px;}

		figure {
			margin: 0;
		}

		code {
            color: #d63384;
            word-wrap: break-word;
            background: #ffedf6;
        }

        pre {
            background: global.$color-paper;
            color: global.$color-mud;
        }

        table th {
            background: global.$color-paper;
        }

		table th,
        table td,
        table th:first-child,
        table td:first-child {
            padding: 8px;

            @media(min-width: global.$mq_tablet) {
                padding: 8px 16px;
            }
        }

        table th,
        table td {
            padding: 8px 16px;
            border: 1px solid global.$color-curtain;
            border-collapse: collapse;
        }

		.wp-block-embed {
			margin-bottom: 1em;
			width: 100%;
			margin-left: 0;
		}

		#ez-toc-container {
			width: 100% !important;
			background: global.$color_light;
			border: none;
			margin-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			border-top: 1px solid global.$color-shadow;
			border-bottom: 1px solid global.$color-shadow;
			border-radius: 0;
			box-shadow: none;

			@media(min-width: global.$mq_tablet) {
				position: fixed;
				bottom: 0;
				right: 0;
				top: 35%;
				left: 2.25vw;
				transform: translateY(-50%);
				width: calc((100% - 800px) / 1.9) !important;
				background: transparent;
				margin-bottom: 1em;
				border: none;
				opacity: 0;
				transition: opacity .3s ease;
			}

			@media(min-width: 1440px) {
				// left: 6vw;
			}

			@media(min-width: 1900px) {
				left: 9vw;
				width: calc(100% - 780px * 2) !important;
			}

			&.enabled {
				@media(min-width: global.$mq_tablet) {
					opacity: 1;
					transition: opacity .3s ease;
				}
			}

			label {
				display: flex;
			}

			p.ez-toc-title {
				text-align: left;
				font-size: 18px;
				margin-top: 14px;
				font-weight: normal;

				@media(min-width: global.$mq_tablet) {
					margin-top: 4px;
				}
			}

			p.ez-toc-title,
			p.ez-toc-title a {
				color: global.$color_ground;
			}

			.ez-toc-cssicon {
				width: auto;
				// display: flex;
				display: none;
				padding: 0 8px;
				border: none;
				font-weight: normal;
			}

			span.ez-toc-icon {
				@media(min-width: global.$mq_tablet) {
					display: none;
				}
			}

			.ez-toc-list {
				list-style: none;
				margin: 0;
				padding-right: 0;
				width: 100%;

				@media(min-width: global.$mq_tablet) {
					margin: initial;
					max-height: 60vh;
					overflow-y: auto;
				}		

				li {
					line-height: 1.5;
					margin: 8px 0;
					font-size: 14px;
					padding: 0;
					list-style: none;
				}

				li a {
					text-decoration: none;
					color: global.$color_ground;

					@media(min-width: global.$mq_tablet) {
						color: global.$color_sand;
					}

					&:hover {
						color: global.$color_dark;
					}
				}

				&::-webkit-scrollbar {
					width: 4px;
				}

				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					background: global.$color_curtain;
				}
			}

			.ez-toc-link {
				font-size: 14px;
			}
		}

		ul, ol, li {
            padding: revert;
        }
	}
}

.post-content ul li,
.wp_comments .comment_message ul li,
.post-content ol li,
.wp_comments .comment_message ol li {
	margin: 0 0 6px; 
	min-height: inherit; 
	display: list-item; 
	padding-left: 0;
	word-break: break-word;
}

#ez-toc-container ul,
#ez-toc-container ol {
	padding: 0;
}