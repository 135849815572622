@use "../global";

.dokusyokai {
    &__items {
        flex-wrap: wrap;

        .col-md-4 {
            @media(min-width: 768px) {
                width: 100%;
            }

            @media(min-width: global.$mq_tablet) {
                width: 33.33333%;
            }
        }
    }

    &__post {
        width: 100%;
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
        color: global.$color_dark;
        margin-bottom: 24px;
        transition: 0.3s ease all;

        @media(min-width: global.$mq_mobile) {
            margin-bottom: 40px;
        }

        &:hover {
            color: global.$color_dark;
            // opacity: 0.875;
        }

        &:hover img {
            transform: scale(1.025);
        }
    }

    &__background {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;

        @media(min-width: global.$mq_tablet) {
            flex-direction: column;
        }

        img {
            width: calc(100vw - 40px);
            margin-bottom: 8px;
            height: 43.41vw;
            object-fit: contain;
            transition: transform 0.35s ease;

            @media(min-width: global.$mq_tablet) {
                width: 100%;
                height: 13vw;
            }
        }
    }

    &__item-header {
        padding: 0;
    }

    &__item-date {
        line-height: 1;
        margin-bottom: 8px;

        @media(min-width: global.$mq_tablet) {
            margin-bottom: 0;
            line-height: 2;
        }
    }

    &__item-header-title {
        font-weight: 500;
        margin: 0;
        line-height: 1.65;
        color: global.$color_mud;
    }
}