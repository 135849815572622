@use "../global";

.archive {
    &__main {
        background: global.$color_light;
    }

    &__desc {
        width: 680px;
    }

    &__items {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}