@use "../global";

.archive .page-head,
.page .page-head {
    padding: 80px 0;
    position: relative;
    z-index: 2;
}

.page {
    .page-content,
    .page-dokusyokai {
		.olam-post-featured-image {
			margin: 0;
		}
	
		img:not(.dokusyokai__post img) {
			max-width: 100%;
			height: auto;
		}
	
		blockquote {
			color: global.$color_dark;
	
			cite {
				font-size: 12px;
				color: global.$color_ground;
	
				@media(min-width: global.$mq_mobile) {
					font-size: 14px;
				}
			}
		}

        h1:not(.page__title),
        h2,
        h3:not(.heading__md.spacing__m-b-32), 
        h4, 
        h5, 
        h6 {
            font-weight: 500;
            margin-top: 40px;
			margin-bottom: 24px;
			font-weight: 500;
			line-height: 1.45;
	
			@media(min-width: global.$mq_mobile) {
				margin-top: 80px;
				margin-bottom: 40px;
			}
        }

        a:not(.button):not(.dokusyokai__post a){
			color: global.$color_dark;
			text-decoration: none;
            border-bottom: 1px solid global.$color_sand;
			line-break: anywhere;
		}

        p {
			line-height: 1.875;
		}

        b, strong, dt {
            font-weight: 600;
        }

        a:not(.dokusyokai__link) {
            text-decoration: underline;
            word-break: break-word;
        }

        ul, ol, li {
            padding: revert;
        }

        blockquote p {
            margin: 0;
        }

        figure {
            margin: 0;
        }

        code {
            color: #d63384;
            word-wrap: break-word;
            background: #ffedf6;
        }

        pre {
            background: global.$color-paper;
            color: global.$color-mud;
        }

        table th {
            background: global.$color-paper;
        }

        table th,
        table td,
        table th:first-child,
        table td:first-child {
            padding: 8px;

            @media(min-width: global.$mq_tablet) {
                padding: 8px 16px;
            }
        }

        table th,
        table td {
            padding: 8px 16px;
            border: 1px solid global.$color-curtain;
            border-collapse: collapse;
        }

        dd {margin-left: 36px;}
    }

    &__header {
        position: relative;
        overflow: hidden;
    }

    &__background,
    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    &__background {
        left: -20px;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        z-index: 0;
        width: 120%;
        height: 120%;

        img {
            width: 100%;
            height: auto;
            filter: blur(15px);
        }
    }

    &__overlay {
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(global.$color-light, 0.75);
    }
}

.page-content ul li,
.wp_comments .comment_message ul li,
.page-content ol li,
.wp_comments .comment_message ol li {
	margin: 0 0 6px; 
	min-height: inherit; 
	display: list-item; 
	padding-left: 0;
	word-break: break-word;
}