@use "../global";

.spacing {
    &__p-b-80 {
        padding-bottom: 56px;

        @media(min-width: global.$mq_tablet) {
            padding-bottom: 80px;
        }
    }

    &__p-t-80 {
        padding-top: 56px;

        @media(min-width: global.$mq_mobile) {
            padding-top: 80px;
        }
    }

    &__p-b-64 {
        padding-bottom: 32px;

        @media(min-width: global.$mq_tablet) {
            padding-bottom: 64px;
        }
    }

    &__p-t-64 {
        padding-top: 32px;

        @media(min-width: global.$mq_tablet) {
            padding-top: 64px;
        }
    }

    &__p-b-48 {
        padding-bottom: 48px;
    }

    &__p-t-48 {
        padding-top: 48px;
    }

    &__m-b-80 {
        margin-bottom: 80px;
    }

    &__m-t-80 {
        margin-top: 80px;
    }

    &__m-b-64 {
        margin-bottom: 64px;
    }

    &__m-t-64 {
        margin-top: 64px;
    }

    &__m-b-48 {
        margin-bottom: 48px;
    }

    &__m-t-48 {
        margin-top: 48px;
    }

    &__m-b-40 {
        margin-bottom: 40px;
    }

    &__m-t-40 {
        margin-top: 40px;
    }

    &__m-b-32 {
        margin-bottom: 32px;
    }

    &__m-t-32 {
        margin-top: 32px;
    }

    &__m-b-24 {
        margin-bottom: 24px;
    }

    &__m-t-24 {
        margin-top: 24px;
    }

    &__m-b-16 {
        margin-bottom: 16px;
    }

    &__m-t-16 {
        margin-top: 16px;
    }
}