@use "../global";

.booklet-template {
    width: 100%;
    overflow-x: auto;
    background: global.$color_paper;

    .single-booklet {
        position: fixed;
        width: 100%;
        height: 100%;
        writing-mode: vertical-rl;
        overflow-x: auto;
        overflow-y: hidden;

        &__main {
            height: 100%;
            position: relative;
        }
    }
}

.booklet-content {
    height: 100%;
    // // TODO: might change this code depends on how it works
    // writing-mode: vertical-rl;

    a:not(.button):not(.login-button):not(.booklet-review__link):not(.fancybox.image) {
        border-right: 0.5px solid global.$color_dark;
        padding-right: 2px;
    }

    .entry-header,
    .entry-header img {
        height: 100%;
    }

    .entry-header {
        width: 100vw;
        height: 100%;
        position: relative;

        @media(min-width: 1000px) {
            width: 80vw;
        }
    }

    .entry-header img {
        width: 100%;
        object-fit: cover;
    }

    &__overlay,
    &__title {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    &__overlay {
        width: 100%;
        background: linear-gradient(90deg, rgba(242, 240, 239, 1), 75%, rgba(242, 240, 239, 0));
    }

    &__title {
        width: 100%;
        padding: calc(1.25rem + 2rem + 20px) 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(min-width: global.$mq_mobile) {
            height: calc(var(--vh, 1vh) * 100);
            margin: auto;
            padding: calc((100% - 480px) / 2);
        }

        &_main,
        &_sub {
            margin: 0 8px;
            line-height: 1.5;
        }
    }

    &__content {
        height: calc(var(--vh, 1vh) * 90);
        margin: auto 96px auto auto;
        padding: 48px 0 8px;

        @media(min-width: global.$mq_mobile) {
            height: calc(var(--vh, 1vh) * 100);
            max-height: 480px;
            margin: auto;
            // padding: 48px 0 16px;
            padding: 0;
        }

        .ez-toc-title {
            font-size: calc(1.3rem + .6vw);

            @media(min-width: global.$mq_tablet) {
                font-size: 1.75rem;
            }
        }

        h2, h3, h4, h5, h6 {
            line-height: normal;
        }

        h2:not(.booklet-review__title) {
            margin: 0;
            padding: 0 16rem 0 12rem;
        }

        h3 {
            margin: 0;
            padding: 0 4rem 0 1.75rem;
        }

        h4 {
            margin: 0;
            padding: 0 3rem 0 1.5rem;
        }

        h5 {
            margin: 0;
            padding: 0 2rem 0 1rem;
        }

        p {
            text-indent: 1rem;
            margin: 0;
            line-height: 1.85;
        }

        ul:not(.content-social__con, .ez-toc-list),
        ol:not(.content-social__con, .ez-toc-list) {
            padding-top: 16px;
            margin-left: 40px;
        }

        li {
            // resolve for firefox line-height
            line-height: 1.875;
        }

        .share {
            *::-webkit-scrollbar {
                width: 8px;
            }
    
            *::-webkit-scrollbar-track {
                background: global.$color_curtain;
            }
    
            *::-webkit-scrollbar-thumb {
                background: global.$color_floor;
            }

            &__cont {
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
            }
        }

        .iframe-cont {
            height: 100%;
            width: 280px;
            height: 100%;
            min-height: 480px;
            height: max-content;
            
            .twitter-timeline iframe {
                width: 100% !important;
            }
        }

        // iframe:not(.iframe-cont) {
        //     margin: 0 40px;
        // }

        .note_caller {
            padding: 0 2px;
            color: global.$color_dark;
            font-weight: 600;
            line-height: 1.5;
            text-indent: 0;
            background: global.$color_sheets;
        }

        .note {
            margin: 0 2rem;
            padding: 0.85rem 0.5rem;
            background: transparent;
            border: 1px solid global.$color_dark;
            color: global.$color_dark;
            font-size: 14px;
            font-weight: 600;
        }
    }

    #ez-toc-container {

        p.ez-toc-title,
        ul.ez-toc-list,
        p.ez-toc-title+ul.ez-toc-list {
            height: 100%;
            max-height: 480px;
            margin: auto 0;
        }

        p.ez-toc-title {
            text-align: left;
            font-size: 18px;
            margin-left: 4px;
        }

        ul.ez-toc-list {
            list-style: none;
        }

        span.ez-toc-toggle {
            position: fixed;
            bottom: 40px;
            right: 100%;
            min-width: 48px;
            width: auto;
            height: 130px;
            padding: 16px 0;
            background: global.$color_light;
            color: transparent;
            text-align: center;
            line-height: 48px;
            opacity: 0;
            z-index: 1;
            visibility: hidden;
            transition: all 1s ease;

            &.enabled {
                opacity: 1;
                visibility: visible;
                transition: all 1s ease;
            }

            a {
                color: global.$color_dark;
                text-decoration: none;
            }
        }
    }

    .edd-submit,
    input[type="submit"].edd-submit,
    .button_content-vert {
        height: 300px;
        width: 48px;
        margin: auto 0 0;

        &::before {
            margin-right: 0 !important;
            margin-bottom: 8px;
        }
    }
}