$color_light: #fff;
$color_paper: #F2F0EF;
$color_curtain: #E2DFDD;
$color_shadow: #dedede;
$color_sheets: #e7e1de;
$color_floor: #9D9999;
$color_sand: #807C7C;
$color_ground: #656262;
$color_mud: #464141;
$color_dark: #212529;

$typography: 'Yu Mincho', '游明朝', 'YuMincho', 'Noto Serif JP', 'Hiragino Mincho ProN', 'ヒラギノ明朝 Pro', 'HGP明朝B', serif;

$mq_mobile: 600px;
$mq_tablet: 996px;