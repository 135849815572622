@use "../global";

.content-social,
.content-social-booklet {
	.content-social__con {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
	}

	.button_social {
		display: flex;
		min-height: 40px;
		padding: 4px 0;
		align-items: center;
		line-height: 40px;
		background: transparent;
		filter: blur(0.8);
		color: global.$color_sand;
		border: 1px solid global.$color_sand;
		transition: all 0.3s ease-in-out;
	
		i,
		svg {
			@media(min-width: 680px) {
				margin-right: 4px;
			}
		}
	
		svg {
			width: 32px;
			fill: global.$color_sand;
			flex-shrink: 0;
	
			@media(min-width: 1280px) {
				width: 40px;
			}
		}
	
		span {
			width: 100%;
			text-align: center;
			line-height: 40px;
		}
	
		&:hover {
			color: global.$color_sand;
			background: global.$color_curtain;
			transition: all 0.3s ease-in-out;
		}
	}
}

.content-social {
	.button_social {
		min-width: 135px;
		width: calc((100vw - 40px) / 2 - 8px);
		margin-bottom: 16px;

		@media(min-width: global.$mq_mobile) {
			width: calc((100vw - 40px) / 2 - 48px);
		}
	
		@media(min-width: 680px) {
			// TODO: temp
			width: 140px;
		}
	}
}

.content-social-booklet {
	margin: 0 5rem;

	.content-social__con {
		li {
			height: 48%;

			&:nth-of-type(odd) {
				margin-bottom: 8px;
			}
	
			&:nth-of-type(even) {
				margin-top: 8px;
			}
		}
	}

	.button_social {
		min-width: 48px;
		width: 48px;
		margin: 0 5px;

		svg {
			margin: 0 0 4px;
		}

		span {
			line-height: 48px;
		}
	}
}