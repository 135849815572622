@use "../global";

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

b, strong {
    font-weight: 600 !important;
}

p, pre {
    font-size: 1em;
}

a:not(.button):not(.login-button) {
    color: global.$color_dark;
    transition: all 0.3s ease;

    &:hover {
        color: global.$color_sand;
        transition: all 0.3s ease;
    }
}

.heading {
    &__xl,
    &__lg,
    &__md {
        font-weight: 500;
    }

    &__xl {
        font-size: 24px;

        @media(min-width: global.$mq_mobile) {
            font-size: 32px;
        }
    }

    &__lg {
        font-size: 24px;

        @media(min-width: global.$mq_mobile) {
            font-size: 24px;
        }
    }

    &__md {
        font-size: 20px;

        @media(min-width: global.$mq_mobile) {
            font-size: 20px;
        }
    }

    &__sm {
        font-size: 18px;

        @media(min-width: global.$mq_mobile) {
            font-size: 18px;
        }
    }
}

.pg {
    &__lg {
        font-size: 16px;

        @media(min-width: global.$mq_mobile) {
            font-size: 16px;
        }
    }

    &__md {
        font-size: 14px;

        @media(min-width: global.$mq_mobile) {
            font-size: 14px;
        }
    }

    &__sm {
        font-size: 12px;

        @media(min-width: global.$mq_mobile) {
            font-size: 12px;
        }
    }
}

.wt {
    &__vert {
        writing-mode: vertical-rl;
    }
}

.text {
    &__bold {
        font-weight: 600 !important;
    }

    &__mid {
        font-weight: 500 !important;
    }
}