@use "../global";

ul, ol, li {
    margin: 0;
    padding: 0;
}

.width {
    &__x-large,
    &__large,
    &__mid,
    &__small {
        width: calc(100% - 40px);
        margin: auto;
    }

    &__full {
        width: 100%;
        margin: auto;
    }

    &__x-large {

        @media(min-width: global.$mq_mobile) {
            width: calc(100% - 40px);
        }

        @media(min-width: global.$mq_tablet) {
            width: calc(100% - 80px);
            max-width: 2000px;
        }
    }

    &__large {

        @media(min-width: global.$mq_mobile) {
            width: calc(100% - 80px);
        }

        @media(min-width: global.$mq_tablet) {
            width: calc(100% - 160px);
            max-width: 2000px;
        }
    }

    &__mid {
        
        @media(min-width: global.$mq_mobile) {
            width: calc(100% - 120px);
        }

        @media(min-width: global.$mq_tablet) {
            width: calc(100% - 240px);
            max-width: 1440px;
        }
    }

    &__small {
        @media(min-width: global.$mq_mobile) {
            width: calc(100% - 120px);
        }

        @media(min-width: global.$mq_tablet) {
            width: calc(100% - 300px);
            max-width: 1100px;
        }
    }

    &__desc {
        width: 100%;
        max-width: 680px;
    }
}

.content {
    &__flex {
        display: flex;
        align-items: center;
    }

    &__flex-col {
        display: flex;
        flex-direction: column;
    }

    &__flex-row-rv {
        display: flex;
        flex-direction: row-reverse;
    }

    &__sb {
        justify-content: space-between;
    }

    &__center {
        justify-content: center;
    }

    &__flex-col-center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.link-text {
    display: block;
    text-align: right;
    color: global.$color_dark;
}

.link-with-bar {
    justify-content: flex-end;
    width: max-content;
    margin-left: auto;

    &__text {
        color: global.$color_dark;
        margin-right: 8px;
        text-align: right;
        font-size: 14px;

        @media(min-width: global.$mq_mobile) {
            margin-right: 20px;
        }
    }

    &__bar {
        width: 60px;
        height: 1px;
        display: block;
        background: global.$color_dark;
        position: relative;
        overflow: hidden;

        @media(min-width: global.$mq_mobile) {
            width: 120px;
        }

        @media(min-width: global.$mq_tablet) {
            width: 200px;
        }

        &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 0;
            left: 0;
        }

        &_light::after {
            background: global.$color_light;
        }

        &_paper::after {
            background: global.$color_paper;
        }
    }

    &:hover .link-with-bar__bar::after {
        width: 100%;
        height: 1px;
        animation: border-animation 2.25s alternate ease-out forwards;
    }
}

@keyframes border-animation {
    from {
        left: 0;
        transform: translateX(-100%);
    }
    
    50% {
        left: 100%; 
        transform: translateX(100%);
    }
    
    to {
        left: 100%;
    }
}