@use "../global";

.edd-submit,
input[type="submit"].edd-submit,
.button_content-vert {
    background: global.$color_ground;
    color: global.$color_light;
    padding: 16px 8px !important;
    font-size: 16px;
    line-height: 1.2em; 
    letter-spacing: .03em; 
    white-space: nowrap; 
    text-align: center;
    border-radius: 0; 
    display: inline-block; 
    text-transform: uppercase; 
    display: flex;
    align-items: center;
    border: none;

    &:hover,
    &:focus {
        color: global.$color_light;
        opacity: 0.75;
    }
}

.edd_go_to_checkout.button.blue.edd-submit,
.edd-add-to-cart.button.blue.edd-submit.edd-has-js,
.button_content-vert {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edd {
    &__cart-button {
        margin: 0 auto;
        height: auto;
        padding: 4px;
    }

    &__purchase-row {
        border-bottom: 1px solid global.$color_shadow;
    }
}

.edd_purchase_submit_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: stretch;
    margin-left: 48px;
}