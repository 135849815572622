@use "../global";

.indicator {
    position: absolute;
    top: 92%;
    right: 50%;
    height: 30px;
    transform: translate3d(50%, 0, 0);
    -webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;
    white-space: nowrap;
    z-index: 1;

    @media(min-width: global.$mq_tablet) {
        right: 48%;
    }

    .indicator_safari {
        position: absolute;
        top: 94%;
        right: 45%;
        height: 30px;
        transform: translate3d(0, 0, 0);
        -webkit-transition: opacity .3s linear;
        transition: opacity .3s linear;
        white-space: nowrap;
        animation: repaint 1ms;
        animation-iteration-count: infinite;

        @media(min-width: global.$mq_tablet) {
            right: 48%;
        }
    }

    @keyframes repaint {
        from {
            width: 99.999%;
        }
        to {
            width: 100%;
        }
    }

    &__text {
        position: relative;
        margin: 0;
        font-size: 12px;
        writing-mode: horizontal-tb;
        line-height: 30px;
    }

    &__bars {
        &_mb {
            position: absolute;
            width: auto;
            bottom: 19px;
        }

        &_dt {
            position: absolute;
            bottom: 0;
            right: -10px;
            left: 0;
            width: auto;
            transform: rotate(90deg);
        }
    }

    &__bar {
        position: absolute;
        left: -30px;
        width: 8px;
        height: 8px;
        margin-left: 15px;
        border-top: 1px solid global.$color-dark;
        border-left: 1px solid global.$color-dark;
        transform: rotateZ(-45deg);
        animation: scroll 2s infinite;

        &:nth-of-type(2) {
            left: -40px;
            animation-delay: .15s;
        }

        &:nth-of-type(3) {
            left: -50px;
            animation-delay: .3s;
        }

        @keyframes scroll {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }
    }
}
