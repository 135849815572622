@use "../global";

.section-tags {
    background: global.$color_light;

    &__header *,
    &__content {
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        width: 100%;
        margin-bottom: 24px;
        line-height: 1.25;
        text-align: center;
    }

    &__desc {
        color: global.$color_mud;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
    }

    &__tag-item {
        list-style: none;
        margin: 2px 8px;
        line-height: 1.75;
        // border-bottom: 1px solid #212529;
        // margin: 0 16px 16px 0;
        // display: inline-block;
        // line-height: 2;

        a {
            display: flex;
            align-items: center;
        }

        a::before {
            content: '#';
            display: block;
        }
    }
}